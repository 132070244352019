import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-jss";
import Bars from "../../../../../components/Dashboard/Charts/Bars";
import Ranking from "../../../../../components/Dashboard/Charts/Ranking";
import Area from "../../../../../components/Dashboard/Charts/Area";
import FiltersContext from "../../../../../contexts/filtering";
import api from "../../../../../services/api";
import LineWithDifferentThickness, {
  generateColor,
} from "../../../../../components/Dashboard/Charts/LineWithDifferentThickness";

export default function WaterSurfaceStatistics() {
  const { values } = useContext(FiltersContext);
  const [areaData, setAreaData] = useState();
  const [barsData, setBarsData] = useState();
  const [percentilesData, setPercentilesData] = useState();
  const [rankingData, setRankingData] = useState();
  const [rankingTotalPages, setRankingTotalPages] = useState();
  const [rankingPage, setRankingPage] = useState(1);
  const [rankingOrder, setRankingOrder] = useState(true);
  const [linesData, setLinesData] = useState();

  const { t } = useTranslation();
  const theme = useTheme();

  /**
   * This userEffect updates area chart data.
   */
  useEffect(() => {
    let isSubscribed = true;
    if (values.grouping) {
      api
        .get(`/surface/time-series`, {
          params: {
            timeMode: values.timeMode,
            years: values.years,
            months: values.months,
            grouping: values.grouping,
            territory: values.territory.type,
            territoryCode: values.territory.code,
            dataType: values.dataType,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            data.series = data.series.map((obj) => {
              obj.color = theme.primary;
              obj.name = t(`dashboard.statistics.charts.${obj.name}.title`);
              return obj;
            });
            setAreaData(data);
          }
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.timeMode,
    values.years,
    values.months,
    values.grouping,
    values.dataType,
    t,
  ]);

  /**
   * This userEffect updates percentiles chart data.
   */
  useEffect(() => {
    let isSubscribed = true;
    if (values.grouping) {
      api
        .get(`/surface/percentiles`, {
          params: {
            territory: values.territory.type,
            territoryCode: values.territory.code,
            grouping: values.grouping,
            timeMode: values.timeMode,
            years: values.years,
            months: values.months,
            dataType: values.dataType,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            data.series = data.series.map((obj) => {
              obj.color = theme.primary;
              obj.name = t(`dashboard.statistics.charts.percentiles.title`);
              return obj;
            });
            setPercentilesData(data);
          }
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.timeMode,
    values.years,
    values.months,
    values.grouping,
    values.dataType,
    t,
  ]);

  /**
   * This userEffect updates progresses bar data.
   */
  useEffect(() => {
    let isSubscribed = true;
    if (values.grouping) {
      api
        .get(`/surface/statistics`, {
          params: {
            timeMode: values.timeMode,
            years: values.years,
            months: values.months,
            grouping: values.grouping,
            territory: values.territory.type,
            territoryCode: values.territory.code,
            dataType: values.dataType,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setBarsData(data);
          }
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.timeMode,
    values.years,
    values.months,
    values.grouping,
    values.dataType,
    t,
  ]);

  /**
   * This userEffect gets new ranking data every time the filter values are modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (values.grouping) {
      api
        .get(`/surface/ranking`, {
          params: {
            timeMode: values.timeMode,
            territory: values.territory.type,
            territoryCode: values.territory.code,
            grouping: values.grouping,
            years: values.years,
            months: values.months,
            order: rankingOrder ? "desc" : "asc",
            page: 1,
            dataType: values.dataType,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            setRankingTotalPages(data.pageAmount);
            data.series = data.series.map((obj) => {
              obj.color = theme.primary;
              obj.name = t(`dashboard.statistics.charts.waterSurface.title`);
              return obj;
            });
            setRankingData(data);
            setRankingPage(1);
            if (data.territoryPage > 0) setRankingPage(data.territoryPage);
          }
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.code,
    values.territory.type,
    values.grouping,
    values.timeMode,
    values.years,
    values.months,
    rankingOrder,
    values.dataType,
    t,
  ]);

  /**
   * This userEffect gets new ranking data every time the ranking page is modified.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (values.grouping) {
      api
        .get(`/surface/ranking`, {
          params: {
            timeMode: values.timeMode,
            territory: values.territory.type,
            territoryCode: values.territory.code,
            grouping: values.grouping,
            years: values.years,
            months: values.months,
            order: rankingOrder ? "desc" : "asc",
            page: rankingPage,
            dataType: values.dataType,
          },
        })
        .then(({ data }) => {
          if (isSubscribed) {
            data.series = data.series.map((obj) => {
              obj.color = theme.primary;
              obj.name = t(`dashboard.statistics.charts.waterSurface.title`);
              return obj;
            });
            setRankingData(data);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, [rankingPage]);

  /**
   * This userEffect updates progresses line data.
   */
  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/surface/monthly-time-series-by-year`, {
        params: {
          territory: values.territory.type,
          territoryCode: values.territory.code,
          years: values.years,
          grouping: values.grouping,
        },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          data.series = data.series.map((obj, index) => {
            obj.color = generateColor(index, data.series.length);
            obj.lineWidth = index === data.series.length - 1 ? 4 : 2;

            obj.name = `Ano ${obj.year}`;
            return obj;
          });
          setLinesData(data);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [
    values.territory.type,
    values.territory.code,
    values.years,
    values.grouping,
    t,
  ]);

  return useMemo(
    () => (
      <>
        {areaData && (
          <Area
            title={`${t(
              "dashboard.statistics.charts.waterSurface.series.title"
            )} - ${values.territory.name}`}
            info={t("dashboard.statistics.charts.waterSurface.series.info")}
            data={areaData}
          />
        )}
        {barsData && (
          <Bars
            data={barsData}
            translationPrefix="dashboard.statistics.charts.waterSurface.bars."
          />
        )}
        {linesData && (
          <LineWithDifferentThickness
            data={linesData}
            title={`${t(
              "dashboard.statistics.charts.waterSurface.lines.title"
            )} - ${values.territory.name}`}
            info={t("dashboard.statistics.charts.waterSurface.lines.info")}
          />
        )}
        {percentilesData && (
          <Area
            title={`${t(
              "dashboard.statistics.charts.percentiles.series.title"
            )} - ${values.territory.name}`}
            info={t("dashboard.statistics.charts.percentiles.series.info")}
            data={percentilesData}
            format={(item) => `${item}%`}
          />
        )}
        {rankingData && (
          <Ranking
            title={t("dashboard.statistics.charts.waterSurface.ranking.title")}
            data={rankingData}
            info={t("dashboard.statistics.charts.waterSurface.ranking.info")}
            totalPages={rankingTotalPages}
            page={rankingPage}
            setRankingPage={setRankingPage}
            rankingOrder={rankingOrder}
            setRankingOrder={setRankingOrder}
          />
        )}
      </>
    ),
    [
      linesData,
      areaData,
      barsData,
      rankingData,
      rankingPage,
      values.territory.name,
      t,
    ]
  );
}
