import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import HeaderRoute from "./components/Router/HeaderRoute";
import Water from "./pages/Water";
import Methodology from "./pages/Methodology";
import Glacier from "./pages/Glacier";

/**
 * Does the platform routing.
 */
function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <HeaderRoute
          exact
          path="/water/:lat?/:lng?/:zoom?/:initiative?/:territoryType?/:territoryCode?/:grouping?/:analysis?/:initialTime?/:endTime?/"
          component={Water}
        />
        <HeaderRoute
          exact
          path="/glacier/:lat?/:lng?/:zoom?/:initiative?/:territoryType?/:territoryCode?/:grouping?/:analysis?/:initialTime?/:endTime?/"
          component={Glacier}
        />
        <HeaderRoute exact path="/methodology" component={Methodology} />
        <Redirect from="*" to="/water" />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
