/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useTheme } from "react-jss";
import useStyles from "./styles";
import CustomTypography from "../../CustomTypography";
import SvgClose from "../../Icons/Close";
import FiltersContext from "../../../contexts/filtering";

/**
 * This function renders a component that handles the map attention page.
 * @returns The attention page.
 */
function Attention({ close }) {
  Attention.propTypes = {
    close: PropTypes.func.isRequired,
  };

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    values: { initiative },
  } = useContext(FiltersContext);

  return (
    <div className={classes.background}>
      <div
        className={classes.exit}
        role="button"
        onClick={() => close()}
        onKeyDown={() => {}}
        tabIndex={0}
      />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.header}>
            <CustomTypography variant="title">
              {t("dashboard.attention.title")}
            </CustomTypography>
            <Button className={classes.closeButton} onClick={() => close()}>
              <SvgClose className={classes.closeIcon} />
            </Button>
          </div>
          <div className={classes.content}>
            <div className={classes.text}>
              <CustomTypography
                style={{ marginBottom: 10, textAlign: "justify" }}
                variant="body"
                color={theme.text.gray}
              >
                <Trans
                  i18nKey={`dashboard.attention.${
                    initiative === "brazil" ? initiative : "rest"
                  }`}
                  values={{
                    email: "contato@mapbiomas.org",
                    link: window.location.origin,
                  }}
                  components={[<a />]}
                />
              </CustomTypography>

              {/* <CustomTypography
                variant="body"
                color={theme.text.gray}
                style={{
                  marginBottom: 10,
                  textAlign: "justify",
                }}
              >
                {t("dashboard.attention.p8")}
                {"\n "}
                <a href="mailto:contato@mapbiomas.org">
                  contato@mapbiomas.org
                </a>{" "} 
                {t("dashboard.attention.p9")}
              </CustomTypography> */}
            </div>
            <Button
              className={classes.copyButton}
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => close()}
            >
              <CustomTypography variant="contrast" color={theme.white}>
                {t("dashboard.attention.button")}
              </CustomTypography>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attention;
