/* eslint-disable no-unused-vars */
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { options, defaults } from "../constants/general";
import api from "../services/api";
import useGrouping from "../hooks/grouping";
import LocationContext from "./location";
import useYears from "../hooks/years";
import useMonths from "../hooks/months";

const FiltersContext = createContext({});

/**
 * The FiltersProvider is context to provide options to filters.
 * */
export function FiltersProvider({ children }) {
  FiltersProvider.propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
  };

  const params = useParams();
  const { handleFiltersRoute, dataType } = useContext(LocationContext);

  const [territoriesOptions, setTerritoriesOptions] = useState([]);
  const [initiative, setInitiative] = useState(defaults[dataType].initiative);
  const [territory, setTerritory] = useState(defaults[dataType].territory);
  const [analysis, setAnalysis] = useState(defaults[dataType].analysis);
  const [paramsLoaded, setParamsLoaded] = useState(false);
  const [groupingOptions, hierarchy, grouping, setGrouping, forceReset] =
    useGrouping(territory, dataType, paramsLoaded, initiative);

  const [timeOptions, setTimeOptions] = useState();
  const [timeMode, setTimeMode] = useState(defaults[dataType].timeMode);
  const [initialTime, setInitialTime] = useState(
    defaults[dataType].initialTime
  );
  const [endTime, setEndTime] = useState(defaults[dataType].endTime);
  const [filtersUrlLoaded, setFiltersUrlLoaded] = useState(false);
  const years = useYears(initialTime, endTime);
  const months = useMonths(initialTime, endTime);
  const [statisticsOpened, setStatisticsOpened] = useState(true);
  const [filtersDesktopOpened, setFiltersDesktopOpened] = useState(true);
  const [filtersWaterClassification, setFiltersWaterClassification] = useState({
    natural: true,
    anthropic: true,
    hydroelectric: true,
    mining: true,
    aquaculture: true,
  });
  const [initiativeOptions, setInitiativeOptions] = useState({});

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(`/initiatives`, {
        params: { dataType },
      })
      .then(({ data }) => {
        if (isSubscribed) {
          const initiatives = {};

          data.forEach((initiativeAux) => {
            const translation = `dashboard.filters.initiatives.options.${initiativeAux.type}`;
            initiatives[initiativeAux.type] = {
              key: initiativeAux.type,
              translation,
              defaultTerritory: initiativeAux.defaultTerritory,
            };

            i18next.addResource(
              "pt",
              "translation",
              translation,
              initiativeAux.namePt
            );
            i18next.addResource(
              "en",
              "translation",
              translation,
              initiativeAux.nameEn
            );
            i18next.addResource(
              "es",
              "translation",
              translation,
              initiativeAux.nameEs
            );
          });

          setInitiativeOptions(initiatives);
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [dataType]);

  const analysisOptions = useMemo(() => {
    if (initiativeOptions) {
      if (options[dataType].analysis[initiative]) {
        return options[dataType].analysis[initiative];
      }

      return options[dataType].analysis.rest;
    }

    return options[dataType].analysis;
  }, [dataType, initiative, initiativeOptions]);

  useEffect(() => {
    if (initiativeOptions && options[dataType].analysis[initiative]) {
      if (!options[dataType].analysis[initiative][analysis]) {
        setAnalysis(defaults[dataType].analysis);
      }
    } else if (
      initiativeOptions &&
      !options[dataType].analysis.rest[analysis]
    ) {
      setAnalysis(defaults[dataType].analysis);
    }
  }, [dataType, initiative, initiativeOptions]);

  useEffect(() => {
    let isSubscribed = true;

    api
      .get(
        `/${analysisOptions[analysis].apiName}/dates/${territory.type}/${territory.code}`,
        {
          params: { dataType },
        }
      )
      .then(({ data }) => {
        if (isSubscribed) {
          setTimeOptions(data);

          if (
            timeMode === "annual" &&
            data.years.includes(initialTime) &&
            data.years.includes(endTime)
          ) {
            return;
          }

          if (
            timeMode === "monthly" &&
            data.months.includes(initialTime) &&
            data.months.includes(endTime)
          ) {
            return;
          }

          if (data.years.length > 0) {
            if (analysis === analysisOptions.surface?.key) {
              setInitialTime(data.years[0].toString());
              setEndTime(data.years[data.years.length - 1].toString());
              setTimeMode("annual");
            } else if (analysis === analysisOptions.transition?.key) {
              if (initialTime.includes("-")) {
                setInitialTime(data.years[0].toString());
                setEndTime(data.years[data.years.length - 1].toString());
                setTimeMode("annual");
              } else {
                setInitialTime(data.years[0].toString());
                setEndTime(data.years[timeOptions.years.length - 1].toString());
                setTimeMode("annual");
              }
            } else if (analysis === analysisOptions.trend?.key) {
              if (initialTime.includes("-")) {
                setInitialTime(data.years[0].toString());
                setEndTime(data.years[data.years.length - 1].toString());
              } else {
                setInitialTime(data.years[0].toString());
                setEndTime(data.years[data.years.length - 1].toString());
                setTimeMode("annual");
              }
            } else if (analysis === analysisOptions.waterClassification?.key) {
              setInitialTime(data.years[data.years.length - 1].toString());
              setEndTime(data.years[data.years.length - 1].toString());
              setTimeMode("annual");
            }
          }
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [analysis, territory]);

  /**
   * Reset essential filtering options
   */
  function resetFiltering() {
    setTerritoriesOptions([]);
    if (dataType === "water") {
      setTerritory(initiativeOptions[initiative].defaultTerritory);
    } else {
      setTerritory(defaults[dataType].territory);
    }
    forceReset();
  }

  /**
   * This useEffect adds territory types translations.
   */
  useEffect(() => {
    let isSubscribed = true;

    api.get("/territories/translations").then(({ data }) => {
      if (isSubscribed) {
        Object.keys(data).forEach((keys) => {
          i18next.addResource(
            "pt",
            "translation",
            `dashboard.filters.territory.type.${keys}`,
            data[keys].pt
          );
          i18next.addResource(
            "en",
            "translation",
            `dashboard.filters.territory.type.${keys}`,
            data[keys].en
          );
          i18next.addResource(
            "es",
            "translation",
            `dashboard.filters.territory.type.${keys}`,
            data[keys].es
          );
        });
      }
    });

    return () => {
      isSubscribed = false;
    };
  }, []);

  /**
   * This function handle the url address according to the current filter params.
   */
  useEffect(() => {
    if (
      paramsLoaded &&
      territory &&
      grouping &&
      analysis &&
      initialTime &&
      endTime
    ) {
      handleFiltersRoute(
        dataType,
        initiative,
        territory,
        grouping,
        analysis,
        initialTime,
        endTime
      );
      setFiltersUrlLoaded(true);
    }
  }, [paramsLoaded, territory, grouping, analysis, initialTime, endTime]);

  /**
   * This useEffect will check if the filters params were passed correctyl.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (!paramsLoaded && groupingOptions && timeOptions) {
      if (
        params.initiative &&
        params.territoryType &&
        params.territoryCode &&
        params.grouping &&
        params.initialTime &&
        params.endTime
      ) {
        /* Monthly date */
        if (params.initialTime.includes("-") && params.endTime.includes("-")) {
          if (params.analysis === analysisOptions.transition?.key) {
            if (params.initialTime === params.endTime) {
              setParamsLoaded(true);
              return () => {
                isSubscribed = false;
              };
            }
          }
          if (params.analysis === analysisOptions.trend?.key) {
            setParamsLoaded(true);
            return () => {
              isSubscribed = false;
            };
          }
          if (params.analysis === analysisOptions.waterClassification?.key) {
            setParamsLoaded(true);
            return () => {
              isSubscribed = false;
            };
          }
          if (
            !(
              timeOptions.months.includes(params.initialTime) &&
              timeOptions.months.includes(params.endTime)
            )
          ) {
            setParamsLoaded(true);
            return () => {
              isSubscribed = false;
            };
          }
        } else if (
          /* Yearly date */
          !params.endTime.includes("-") &&
          !params.initialTime.includes("-")
        ) {
          if (params.analysis === analysisOptions.transition?.key) {
            if (params.initialTime === params.endTime) {
              setParamsLoaded(true);
              return () => {
                isSubscribed = false;
              };
            }
          }

          if (params.analysis === analysisOptions.trend?.key) {
            if (params.endTime - params.initialTime < 4) {
              setParamsLoaded(true);
              return () => {
                isSubscribed = false;
              };
            }
          }

          if (params.analysis === analysisOptions.waterClassification?.key) {
            if (params.endTime !== params.initialTime) {
              setParamsLoaded(true);
              return () => {
                isSubscribed = false;
              };
            }
          }

          /* if (
            !(
              timeOptions.yearsWithMonths[params.initialTime] &&
              timeOptions.yearsWithMonths[params.endTime]
            )
          ) {
            setParamsLoaded(true);
            return () => {
              isSubscribed = false;
            };
          } */
        } else {
          setParamsLoaded(true);
          return () => {
            isSubscribed = false;
          };
        }

        if (analysisOptions[params.analysis]) {
          api
            .get(
              `/territories/search/${params.territoryCode}/${params.territoryType}`,
              { params: { dataType, initiative: params.initiative } }
            )
            .then(({ data }) => {
              if (isSubscribed) {
                if (data.code !== undefined) {
                  setInitiative(params.initiative);
                  setTerritory(data);
                  setGrouping(params.grouping);
                  setAnalysis(params.analysis);
                  setInitialTime(params.initialTime);
                  setEndTime(params.endTime);
                  setTimeMode(
                    params.initialTime.includes("-") ? "monthly" : "annual"
                  );
                }
                setParamsLoaded(true);
              }
            })
            .catch(() => {
              setParamsLoaded(true);
            });
        } else {
          setParamsLoaded(true);
        }
      } else {
        setParamsLoaded(true);
      }
    }

    return () => {
      isSubscribed = false;
    };
  }, [groupingOptions, paramsLoaded, timeOptions]);

  /**
   * Loads route params and sets the viewport.
   */
  useEffect(() => {
    let isSubscribed = true;

    if (params.territoryType && params.territoryCode && params.analysis) {
      api
        .get(
          `/territories/search/${params.territoryCode}/${params.territoryType}`,
          { params: { dataType, initiative } }
        )
        .then(({ data }) => {
          if (isSubscribed) {
            setTerritory(data);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
  }, []);

  function handleInitiative(newInitiative) {
    setInitiative(newInitiative);
    setTerritoriesOptions([]);
    setTerritory(initiativeOptions[newInitiative].defaultTerritory);
    forceReset();
  }

  return (
    <FiltersContext.Provider
      value={{
        options: {
          territoriesOptions,
          analysisOptions,
          groupingOptions,
          timeOptions,
          initiativeOptions,
        },
        values: {
          dataType,
          territory,
          analysis,
          grouping,
          timeMode,
          initialTime,
          endTime,
          years,
          months,
          hierarchy,
          statisticsOpened,
          filtersDesktopOpened,
          filtersWaterClassification,
          initiative,
        },
        setters: {
          setAnalysis,
          setTerritory,
          setTerritoriesOptions,
          setGrouping,
          setTimeMode,
          setInitialTime,
          setEndTime,
          setStatisticsOpened,
          setFiltersDesktopOpened,
          setFiltersWaterClassification,
          resetFiltering,
          handleInitiative,
        },
        loading: {
          filtersUrlLoaded,
        },
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
}

export default FiltersContext;
