import React, { useState, useContext } from "react";
import { Button } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useHistory, useLocation } from "react-router-dom";
import SvgOptions from "../../Icons/Options";
import useStyles from "./styles";
import MobileSwitch from "../../MobileSwitch";
import ThemeContext from "../../../contexts/theming";
import LogoImg from "../../../assets/images/agua_logo.png";
import SvgClose from "../../Icons/Close";
import CustomTypography from "../../CustomTypography";
import SvgDarkMode from "../../Icons/DarkMode";
import SvgLanguage from "../../Icons/Language";
import MenuItem from "./MenuItem";
import SvgBack from "../../Icons/Back";

/* Gets all the available languages at the platform */
const availableLanguages = i18next.options.resources;

/**
 * This functional component renders the menu button and its options.
 * @returns A button that opens the platform menu.
 */
function MenuButton() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { themeName, switchTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [translationMenu, setTranslationMenu] = useState(false);
  const location = useLocation();
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const handleClickMethod = () => {
    if (location.pathname === "/methodology") {
      history.push("/map");
    } else {
      history.push("/methodology");
    }
  };

  return (
    <>
      <Button className={classes.button} onClick={() => setOpenDrawer(true)}>
        <SvgOptions className={classes.icon} />
      </Button>
      <Drawer
        anchor="right"
        open={openDrawer}
        classes={{ root: classes.list }}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
      >
        <div className={classes.topContainer}>
          <img src={LogoImg} alt="logo" className={classes.logo} />
          <Button
            className={classes.closeButton}
            onClick={() => setOpenDrawer(false)}
          >
            <SvgClose className={classes.closeIcon} />
          </Button>
        </div>
        <div className={classes.items}>
          {/* <MenuItem
            buttonProps={{
              target: "_blank",
              href: "https://mapbiomas.org/",
            }}
          >
            {t(`header.buttons.mapbiomas`)}
          </MenuItem> */}
          {/* <MenuItem arrow buttonProps={{ onClick: () => handleClickMethod() }}>
            {location.pathname === "/methodology"
              ? t("header.buttons.map")
              : t("header.buttons.method")}
          </MenuItem> */}
          <MenuItem
            arrow
            buttonProps={{
              onClick: () =>
                window
                  .open("https://dev-agua.geodatin.com/api/docs/#/", "_blank")
                  .focus(),
            }}
          >
            API
          </MenuItem>
          <MenuItem
            arrow
            buttonProps={{
              onClick: () => history.push("/water"),
            }}
          >
            {t("header.buttons.water")}
          </MenuItem>
          <MenuItem
            arrow
            buttonProps={{
              onClick: () => history.push("/glacier"),
            }}
          >
            {t("header.buttons.glacier")}
          </MenuItem>
          <MenuItem
            icon={<SvgLanguage className={classes.darkModeIcon} />}
            arrow
            buttonProps={{ onClick: () => setTranslationMenu(true) }}
          >
            {t(`general.language`)}
          </MenuItem>
          <div className={classes.themeContainer}>
            <span className={classes.themeTitleContainer}>
              <SvgDarkMode className={classes.darkModeIcon} />
              <CustomTypography variant="contrast">
                {t(`dashboard.menu.mobile.darkMode`)}
              </CustomTypography>
            </span>
            <MobileSwitch
              marked={themeName === "dark"}
              setMarked={() => {
                if (themeName === "dark") {
                  switchTheme("light");
                } else {
                  switchTheme("dark");
                }
              }}
            />
          </div>
        </div>
      </Drawer>
      <Drawer
        open={translationMenu}
        anchor="right"
        classes={{ root: classes.list }}
        onOpen={() => setTranslationMenu(true)}
        onClose={() => setTranslationMenu(false)}
      >
        <div className={classes.translationTopContainer}>
          <Button
            className={classes.backButton}
            onClick={() => setTranslationMenu(false)}
          >
            <SvgBack className={classes.closeIcon} />
          </Button>
          <CustomTypography variant="contrast" style={{ marginLeft: 10 }}>
            {t(`dashboard.menu.mobile.language`)}
          </CustomTypography>
        </div>
        <div className={classes.items}>
          {Object.keys(availableLanguages).map((language) => (
            <MenuItem
              key={language}
              arrow
              buttonProps={{
                onClick: () => {
                  i18next.changeLanguage(language);
                  setTranslationMenu(false);
                },
              }}
            >
              {availableLanguages[language].translation.general.language}
            </MenuItem>
          ))}
        </div>
      </Drawer>
    </>
  );
}

export default MenuButton;
